import React from 'react'
import { useAssests } from '../hooks/useAssest';

export const AssestPicker = () => {
    const assests=useAssests();
  return (
    <div>
        {assests.map((asset:any)=>(
            <div className='container'>
            <img src={asset.url} alt="assest-pics" />
            </div>
        ))}
    </div>
  )
}
