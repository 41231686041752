import React from 'react';
import {AssestPicker} from "./component/AssestPicker"

function App() {
  return (
    <div>
      <AssestPicker />
    </div>
  );
}

export default App;
