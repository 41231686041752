import { useState, useEffect } from "react";

export const useAssests = () => {
  const [assest, setAssest] = useState([]);
  useEffect(() => {
    fetch(
      "https://cdn.contentstack.io/v3/assets?environment=testing&include_fallback=true&relative_urls=false&include_dimension=true&include_branch=false",
      {
        headers: {
          "Content-Type": "application/json",
          api_key: "blt858e12437ac2679e",
          access_token: "cs2d8ace9b6c6cc3814f098a53",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => setAssest(res.assets));
  }, []);

  return assest;
};
